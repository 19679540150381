@use '../abstract/mixins' as mixin;
@use "../abstract/responsive" as rwd;

$c-white: #FFFFFF;   
$c-athens-gray: #E7EAEE;  

$transition: all .4s ease;  
$border-radius-val: 3px; 

.timetableSection {
  @include rwd.breakpoint(mobileUp) {
    margin-bottom: 20px;
  }

  .timetable {
    &__tabList {
      display: flex;

      @include rwd.breakpoint(mobileUp) {
        margin-top: 20px;
      }

      @include rwd.breakpoint(phonesDown) {
        margin-left: -15px;
        margin-right: -15px;
      }

      .tabItem {
        font-weight: 500;
        min-height: 44px;
        height: 100%;
        border: none;
        background-color: $c-white;
        cursor: pointer;
        
        @include rwd.breakpoint(mobileUp) {
          @include mixin.font(18px, 20px);
          padding: 5px 20px;
        }

        @include rwd.breakpoint(mobile) {
          @include mixin.font(16px, 18px);
          padding: 5px 10px;
        }

        &.active {
          background-color: $c-athens-gray;
        }
      }
    }

    &__bg {
      background-color: $c-athens-gray;
      
      @include rwd.breakpoint(mobileUp) {
        padding: 40px 0;
      }
      
      @include rwd.breakpoint(mobile) {
        padding: 20px 0;
      }
    }

    &__wrapper {
      display: none;

      &.active {
        display: block;
      }
    }

    &__list {
      display: grid;
      grid-gap: 8px;
      
      @include rwd.breakpoint(smallUp) {
        grid-template-columns: repeat(auto-fit, minmax(62px, 1fr));
      }

      @include rwd.breakpoint(tablet) {
        grid-template-columns: repeat(auto-fit, minmax(57px, 1fr));
      }

      @include rwd.breakpoint(mobile) {
        grid-template-columns: repeat(auto-fit, minmax(47px, 1fr));
      }
    }

    &__link {
      @include mixin.flexCenter();
      @include mixin.font(16px, 18px);
      background-color: $c-white;
      border-radius: $border-radius-val;
      padding: 10px 15px;
      text-decoration: none;
      transition: $transition;

      &.active,
      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          background-color: var(--main-color);
          color: $c-white;
        }
      }
    }
  }

  .btnWrapper {
    text-align: right;

    a {
      @include mixin.font(16px, 22px, 600);
      background-color: var(--main-color);
      border-radius: $border-radius-val;
      color: $c-white;
      display: inline-block;
      margin-top: 20px;
      padding: 6px 20px;
      text-decoration: none;
    }
  }
}
